import React from 'react';
import CoffeeTable from './components/CoffeeTable';
import './components/CoffeeTable.css'; // Import the CSS styles

function App() {
  return (
    <div className="App">
      <h1 style={{ textAlign: 'center' }}>Roasted Coffee Needs</h1>
      <CoffeeTable />
    </div>
  );
}

export default App;
